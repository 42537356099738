$border-color: $light-gray;
$border-style: solid;
$border-width: 1px;
$container-border: 1px $border-style $border-color;
$container-box-shadow: 0 3px 25px rgba(0,0,0,.3);

@mixin container-border($shadow: false) {
  border: $container-border;
  border-radius: $global-radius;

  @if $shadow {
    box-shadow: $container-box-shadow;
  } @else {
    box-shadow: none;
  }
}

@mixin border($direction, $width: $border-width, $style: $border-style, $color: $border-color) {
  border-#{$direction}: $width $style $color;
}

.container-divider-right {
  border-bottom: $container-border;

  @include breakpoint(medium) {
    border-right: $container-border;
    border-bottom: 0;
  }
}
