$body-font-family: 'Work Sans', helvetica, sans-serif;
$utility-font-family: 'Arial', sans-serif;

body {
	font-family:$body-font-family;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $body-font-family;
	&.alert {
		color:get-color(alert);
		font-weight:600;
	}
}
h1, h2, h3 {
	font-weight:500; //turn back to 500 for desktop?
	line-height:1.8em;
}
h1 {
	line-height:1.3em;
	padding:.7em 0;
	font-size:2.4rem;
	&.read {
		max-width:900px;
		margin:0 auto;
	}
}
h2 {
	font-size:1.8rem; //turn back to 1.2 for desktop?
		&.read {
		max-width:900px;
		margin:0 auto;
	}
}
h3 {
	font-size:1.3rem;
		&.read {
		max-width:900px;
		margin:0 auto;
	}
}
h4 {
	font-size:1.2em;
	font-weight:600;//turn back to 500 for desktop
		&.read {
		max-width:900px;
		margin:0 auto;
	}
}
h6 {
	
}
.column:last-child:not(:first-child).float-center, .columns:last-child:not(:first-child).float-center {
	float:none !important;	
}
.logo{
	background: url('../img/516_Museum-logo_horizontal_4_noline.jpg') top left  no-repeat ;
	width:300px;
	height: 86px;
	text-indent:-9999px;
	background-size:cover;
	padding:0;
	display:flex;
	margin-bottom:1rem;
	margin-left:.5rem;
}
.space {
	margin-top:1rem;
	margin-bottom:1rem;
}
.orbit-caption p {
	font-size:14.8px;
}
hr{
	border-bottom:3px solid $light-gray;
}
a {
	color:$dark-gray;
	border-bottom:1px solid lighten($medium-gray, 20);
	&:hover {
		color:$medium-gray;
		border-bottom:1px solid #cbcb2c;
	}
	&.grid-image {
    	border-bottom:0;
    	&:hover {
        	border-bottom:0;
        	background-color:#efefef;
    	}
	}
}
.card {
    h5 {font-weight:500;}
	.home-card-image {width:100%; height:250px;display:flex; align-items:center;  background-position:center center; background-repeat:no-repeat; }
}
.row {
	width:100%;
	&.space {
		margin-bottom:2em;
	}
	&.buffer {
		margin-top:2em;
		margin-bottom:2em;
	}
}
figure {
	margin-bottom:6px;
	figcaption {
		margin-top:6px;
		
		min-height:50px; //2 lines
	}
	&.feature {
		margin-top:.5em;
		padding-top:3px;
	//	border-top:2px solid lighten($medium-gray, 20);
		.figcaption {
		border-top:2px solid lighten($medium-gray, 20);
		padding-top:3px;
		}
		&.vertical {
			max-width:600px;
		}
	}
}
.grid-happy {
	margin-left:1rem;
	margin-right:1rem;
}
.quadrant {
	padding-bottom:1em;
	margin-bottom:1em;
	border-bottom:1px solid $light-gray;
}
.read {
	p, ul, h2, h3, h4,h5, h6 {
		max-width:750px;
		margin: 0 auto;
		margin-bottom:1.45rem;
	}
	p, ul{
		font-size: 1.2rem;
	}
}
.top-bar {
	background-color:$white;
	min-height:150px;
	justify-content:space-between;
	display:flex;
	border-bottom:2px solid $light-gray;
	.top-bar-left{
		//display:flex; - doesn't work with btns
	}
	
	
}
.menu a {
	color:$dark-gray;
	font-size:1.1rem;
	line-height:1em;
	border-bottom:3px solid $white;
	transition: border-bottom .3s linear;
	&:hover{
		color:$black;
		border-bottom:3px solid get-color(alert);
	//	-webkit-transition: background-color 3s ease-in-out;
	//	-moz-transition: background-color 3s ease-in-out;
	//	-ms-transition: background-color 3s ease-in-out;
	//	-o-transition: background-color 3s ease-in-out;
	}
	&.selected{
			border-bottom:3px solid get-color(primary);
		}
}
//.mega-menu.menu {
//	overflow:hidden;
//	a{
	//	border-bottom:none;
	//	&:hover{
	//		background-color:$light-gray;
	//	}
	//	&.selected{
	//		border-bottom:3px solid get-color(primary);
	//	}
	//}
//}
.menu .active > a{
	background-color:$dark-gray;
	border-bottom:3px solid get-color(primary);
	color:$white;
}
.shortcuts a {
	border-bottom:none;
}
.sub-menu {
	position:relative;
	line-height:1.5em;
	.close-row{
		height:22px;
		.close-button{
			position:absolute;
			font-size:15px;
			
		}
	}
	ul {
		max-width:100%;
		display:flex;
		align-content:space-between;
		flex-direction:column;
		margin:0 auto;
		li {
		
			font-family:$utility-font-family;
			font-weight:bold;
		//	font-size:15px;
			//border-bottom:1px solid $light-gray;
			padding:1rem 5% 1.3rem 5%;
			text-align:center;
			a {
				color:$dark-gray;
				
				padding:1rem 0;	
				display:inline;
				font-size:17px;
				border-bottom:3px solid #cbcb2c;
				&:hover {
					background-color:$white;
					border-bottom:3px solid get-color(primary);
				}
				
			}

		}
	}
}
figure {
	figcaption {
		color:$dark-gray;
	//	background-color:$light-gray;
		line-height:2.5em;
	}
}
.row {
	>figure {
	margin:1.8rem 0;
	}
	&.old-elements {
		display:flex;
		justify-content: center;
		margin-bottom:1em;
		img {
			float:left;
			margin-right:15px;
		}
		h5{
			display:flex;
			flex-direction:column;
			justify-content:center;
			vertical-align:middle;
			height:100%;
			a{
				display:block;
				flex-direction:column;
				
			}
		}
	}
}
.tabs-title, .accordion-item{
	a {
		border-bottom-color:$medium-gray;
		border-bottom-width:2px;
		h2 {
			color:$dark-gray;
			font-size:1.2rem;
		}
	}
}
.title-box {
	height:63px;
	> *{
	    height: inherit;
	    vertical-align: bottom;
	    margin: 0;
	    display: table-cell;

	}
}
.cell{
	&.image-listing {
		min-height:20em;

	}
}
.square-listing {
 padding-left:2.5rem;
 padding-right:2.5rem; //restore to .625 for desktop
 
h5 {
//	min-height:84px; -- do we need this with equalizer?
font-weight:600;
  } 
}
ul.no-disc{
	margin-left:0;
	li{
		list-style-type:none;
	}
}
.site-footer{
	background-color:$medium-gray;
	border-top:4px solid $dark-gray;
	color: $light-gray;
	padding-top:3rem;
	a{
		color:#d1745a;
		border-bottom:none;
		font-weight:500;
	}
	ul {
		list-style:none;
		li {
			list-style:none;
		}
	}
}

//STYLEGUIDE STYLES

.color-block {
    border-radius: 2px;
    display: block;
    padding: 8px 8px 6px;
    color: #333;
    text-transform: uppercase;
    border: 1px solid #ddd;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    span {
	    display: block;
		width: 100%;
		height: 100px;
		margin-bottom: 0.42857rem; 
	   }
}
/* Small only */
@include breakpoint(small) {
.logo{
	
}
.row.mega-nav{
	-ms-overflow-style: none;
	overflow-x:scroll;
	overflow-y:hidden;
	width:100%;
	position:relative;
	margin-left:0;
	max-height:2.9em;
	&::after {
		content: "";
		position: absolute;
		background: 0 0;
		height: 32px;
		width: 40px;
		top: 0;
		right: 0;
		background: linear-gradient(90deg,rgba(254,254,254,0) 0,#fefefe);
	}
	ul.menu {
		display:flex;
		flex-wrap:nowrap;
		padding-bottom:17px;
		li ul{
			display:none;
		}
	}
/* Medium only @media screen and (min-width: 40em) and (max-width: 63.9375em) {} */
}
}
/* Medium and up */
@include breakpoint(medium) {

	h1 {
		line-height:1.3em;
		padding:.7em 0;
		font-size:2.4rem;
	}
	h2 {
		font-size:2rem;
	}
	.top-bar{
		padding:1rem;
		margin-botom:1rem;
		min-height:130px;
		input {max-width:130px;}
		.top-bar-right {
			flex-grow: 2;
			overflow:hidden;
			.shortcuts {
				justify-content:space-around;
				display:flex;
					max-width:60%;
					margin-left:40%;
					justify-content:space-around;
			}
		}
	}
	.logo {
		margin:1rem 1rem 1rem 0;
	}
	.grid-x .cell img {
		margin-bottom:.5rem;
	}
}
/* Large only @media screen and (min-width: 64em) and (max-width: 74.9375em) {} */

/* Large and up */
@include breakpoint(large) {
	h1, h2, h3, h4 {
		font-weight:500; //turn back to 500 for desktop?
		line-height:1.8em;
	}
		h3 {
		font-size:1.3875rem;
	}
	.square-listing {
	 //padding-left:2.5rem;
	 //padding-right:2.5rem; //restore to .625 for desktop
	 	h5 {
	//	min-height:84px; -- do we need this with equalizer?
		font-weight:500;
	  } 
	}
	.orbit-caption p {
	font-size:16px;
	}
	.grid-happy {
		margin:0;
	}
	.row.mega-nav{
		overflow-x:visible;
	}
	.quadrant {
		margin-top:1em;
		padding-bottom:1em;
		margin-bottom:1em;
		border-bottom:1px solid $white;
	}
	.top-bar {
		padding:1rem 3rem;
		justify-content:space-between;
		align-items:center;
		input{max-width:200px;}
		.top-bar-right {
			max-width:auto;
			float:right;
			flex-grow: 0;
			.shortcuts {
				justify-content:space-around;
				max-width:none;
				align-items:flex-start;
				margin-left:0;
				
			}
				
		}
	}
	.menu {
		justify-contents:flex-end;
		a {
		
		font-size:1.2rem;
		}
		ul a {
			font-size:1.0rem;
			font-family:arial, sans-serif;
			font-weight:bold;
			line-height:1.5em;
		
		}
	}

	.sub-menu {
		max-width:150rem;
		
		.container{
			position:absolute;	
			top:1px;
			right:10px;
			z-index:999;
			background-color:$white;
			border-left:1px solid $light-gray;
			border-right:1px solid $light-gray;
			border-bottom:1px solid $light-gray;
			.subMenuContent{
				margin-bottom:1em;
			}
			.row {
				margin:0;
				&.subMenuContent{
					margin-bottom:1em;
				}
			}
			ul{
				li{
				
				}
			}
		
		}
	}
	.orbit.homepage{
		height:70%;
		max-height:950px;
		 .orbit-wrapper{
			max-height:900px;
			.orbit-slide {
				max-height:900px;
			}
		}
	}
	.row.mega-nav{
    	&::after {
    		content: "";
    		position: static;
    		background: transparent;
    	}
    }
}
  @include breakpoint(xlarge) {
    // All CSS in here goes inside the media query
    .row.mega-nav ul.menu {justify-content:flex-end;}
    .menu {
		a {
		
		font-size:1.3rem;
		}
		ul a {
			font-size:1.0rem;
			font-family:arial, sans-serif;
			font-weight:bold;
			line-height:1.5em;
		
		}
	}
    .orbit.homepage{
		height:100%;
		max-height:1169px;
		 .orbit-wrapper{
			max-height:1200px;
			.orbit-slide {
				
				max-height:1069px;
			}
		}
	}
  }

  @include breakpoint(xxlarge) {
    // All CSS in here goes inside the media query
  }
